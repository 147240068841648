$('.js-burger').click(function(){
	$('.js-nav').toggleClass('c-nav--open');
});

$('.js-tile').responsiveEqualHeightGrid();
$('.js-tile__header').responsiveEqualHeightGrid();
$('.js-tile__body').responsiveEqualHeightGrid();
$('.js-property-block__title').responsiveEqualHeightGrid();
$('.js-property-block__address').responsiveEqualHeightGrid();
$('.js-box').responsiveEqualHeightGrid();
$('.js-box-wrapper').responsiveEqualHeightGrid();

$('.js-news-item__title').responsiveEqualHeightGrid();

//$('.js-work-header').responsiveEqualHeightGrid();
//$('.js-work-description').responsiveEqualHeightGrid();

$('document').ready(function () {
	var trigger = $('#js-burger');

	trigger.click(function(){
		trigger.toggleClass('is-open');
		trigger.toggleClass('is-closed');
		$('.js-mobile-menu').toggleClass('is-open');
		
		//If opening menu with search open thn make sure to pin the header and hide search
		if ($('.js-mobile-search').hasClass('is-open')) {
			$('.js-header').addClass('headroom--menu-open');
			$('.js-mobile-search').removeClass('is-open');
		}
		else{
			$('.js-header').toggleClass('headroom--menu-open');
		}
		
	});

	$(".js-header").headroom();

	$('.js-header__search').click(function(){
		$('.js-search').toggleClass('is-open');
	});

	$('.js-toggle-mobile-search').click(function(){

		$('.js-mobile-search').toggleClass('is-open');

		//If menu is open make sure we close it
		if ($('.js-mobile-menu').hasClass('is-open')) {
			//$('.js-search').addClass('is-open-on-mobile');
			//$('.js-filters').addClass('is-open');
			$('.js-mobile-menu').removeClass('is-open');
			$('.js-header').addClass('headroom--menu-open');
		}
		else{
			//$('.js-search').toggleClass('is-open-on-mobile');
			//$('.js-filters').toggleClass('is-open');
			$('.js-header').toggleClass('headroom--menu-open');
		}
		
		
	});

	$('.js-filter-toggle').click(function(){
		$(this).toggleClass('is-open');
		$('.js-filters').toggleClass('is-open');
	});

	$('.js-mobile-nav__link').click(function(e){
		e.preventDefault();
		$(this).parent().toggleClass('is-open');
	})

	$('.sticky').Stickyfill();
	//$("#js-subnav").stick_in_parent();
	$("#js-property-cta").stick_in_parent();
	//$(".js-share").stick_in_parent();

	$('#js-service-options').stickyTableHeaders({
		fixedOffset: $('#js-subnav')
	});

	$('.js-tab-link').click(function(){
		var tab_id = $(this).attr('data-tab');

		$('.js-tab-link').removeClass('active');
		$('.js-tab').removeClass('active');

		$(this).addClass('active');
		$("#js-"+tab_id).addClass('active');

		$('.js-table').removeClass('table--tab-1');
		$('.js-table').removeClass('table--tab-2');
		$('.js-table').removeClass('table--tab-3');
		$('.js-table').addClass('table--'+tab_id);
	});


	$("#min-price" ).appendAround();
	$("#max-price").appendAround();
	$("#min-beds").appendAround();
	$("#max-beds").appendAround();
	

	$('.js-scroll-to').click(function(e){
		var id = $(this).attr("href")
		e.preventDefault();
		$('html, body').animate({
		    scrollTop: $(id).offset().top - 72
		}, 500);
	});

	$('.js-map-view').click(function(){
		window.location.href = UpdateQueryString('view', 'map');
	});

	$('.js-grid-view').click(function(){
		window.location.href = UpdateQueryString('view', 'grid');
	});

	$('.js-list-view').click(function(){
		window.location.href = UpdateQueryString('view', 'list');
	});

	$('#js-enquiry-form').submit(function(ev) {
	    // Prevent the form from actually submitting
	    ev.preventDefault();

	   	$('#js-submit').val('Sending...');

	   	if (!$('#message').val()) {
	   		$('#message').val('empty');
	   	}

	    // Get the post data
	    var data = $(this).serialize();

	    // Send it to the server
	    $.post('/', data, function(response) {
	        if (response.success) {
	        	$('.js-form-container').addClass('hide');
	            $('#js-thanks').fadeIn();
	        } else {
	            // response.error will be an object containing any validation errors that occurred, indexed by field name
	            // e.g. response.error.fromName => ['From Name is required']
	            alert('An error occurred. Please try again.');
	            $('#js-submit').val('Send');
	        }
	    });
	});

	$('.js-tab-link').click(function(){
		var tab_id = $(this).attr('data-tab');

		$('.js-tab-link').removeClass('current');
		$('.js-tab-content').removeClass('current');

		$(this).addClass('current');
		$("#"+tab_id).addClass('current');
	});

	$('.js-mobile-tab-link').click(function(){
		var tab_id = $(this).attr('data-tab');

		$('.js-mobile-tab-link').removeClass('current');
		$('.js-mobile-tab-content').removeClass('current');

		$(this).addClass('current');
		$("#"+tab_id).addClass('current');
	});


});


function UpdateQueryString(key, value, url) {
    if (!url) url = window.location.href;
    var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
        hash;

    if (re.test(url)) {
        if (typeof value !== 'undefined' && value !== null)
            return url.replace(re, '$1' + key + "=" + value + '$2$3');
        else {
            hash = url.split('#');
            url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
            if (typeof hash[1] !== 'undefined' && hash[1] !== null) 
                url += '#' + hash[1];
            return url;
        }
    }
    else {
        if (typeof value !== 'undefined' && value !== null) {
            var separator = url.indexOf('?') !== -1 ? '&' : '?';
            hash = url.split('#');
            url = hash[0] + separator + key + '=' + value;
            if (typeof hash[1] !== 'undefined' && hash[1] !== null) 
                url += '#' + hash[1];
            return url;
        }
        else
            return url;
    }
}


